@import 'src/utils/utils';

.lk {
  margin-top: 15px;
  margin-right: 16px;
  margin-bottom: 15px;
  margin-left: 16px;

  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(min-width: $xxl) {
    @include box;
  }

  .suggestions-wrapper {
    @include Shadow-1;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
    background-color: $Main-2;
    overflow: hidden;

    .suggestions-suggestions div {
      @include Text-16-reg;
      padding-top: 10px;
      padding-right: 16px;
      padding-bottom: 10px;
      padding-left: 16px;
      transition: background-color 0.3s;
      cursor: pointer;

      &:hover {
        @media(min-width: $lg) {
          background-color: $Main-4;
        }
      }

      &:active {
        background-color: $Main-4;
      }
    }

    .suggestions-suggestions div:last-child {
      display: none;
    }

    .suggestions-hint {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 18px;

    @media(min-width: $lg) {
      gap: 30px;
    }

    @media(min-width: $xxl) {
      max-width: 800px;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr;
    margin-right: -16px;
    margin-left: -16px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media(min-width: $md) {
      grid-template-columns: repeat(2, 1fr);
      margin-right: unset;
      margin-left: unset;
      padding: 0;
      gap: 20px;
    }
  }

  &__user-info {
    padding-top: 10px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    background-color: $Main-2;

    @media(min-width: $md) {
      padding-top: 10px;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-left: 20px;
      border-radius: 10px;
    }
  }

  &__user-name {
    @include Text-12-reg;
    display: block;
    margin: 0;
    color: $t-60;
  }

  &__user-value {
    @include Text-16-reg;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;

    em {
      @include Text-14-reg;
      color: $t-60;
      font-style: normal;
    }
  }

  &__user-link {
    @include Text-14-reg;
    display: inline-grid;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    color: $Main-1;
    background-color: $Main-2;
    text-decoration: none;
    transition: color 0.3s;
    grid-auto-flow: column;
    gap: 5px;
    flex-shrink: 0;

    &:not(:disabled):hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      fill: $Corporate-2;
      stroke: $Corporate-2;
    }
  }

  &__nav {
    display: grid;
    gap: 20px;

    @media(min-width: $md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__inner {
    margin-right: -16px;
    margin-left: -16px;
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    background-color: $Main-2;

    @media(min-width: $md) {
      margin-right: unset;
      margin-left: unset;
      padding-top: 35px;
      padding-right: 40px;
      padding-bottom: 35px;
      padding-left: 40px;
      border-radius: 20px;
    }

    p {
      @include Text-16-reg;
      margin: 0;
      gap: 5px;
  
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  
    a {
      position: relative;
      display: inline-block;
      padding-left: 22px;
      text-decoration: underline;
      color: $Corporate-2;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.3s;

      @media(min-width: $lg) {
        padding-left: 28px;
      }

      &:hover {
        @media(min-width: $lg) {
          text-decoration-color: $Corporate-2;
        }
      }

      &:before {
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url('../../assets/images/link.svg');
        background-size: contain;
        background-position: center;
        content: '';

        @media(min-width: $lg) {
          top: 2px;
          width: 24px;
          height: 24px;
        }
      }
    }

    &--center {
      text-align: center;
    }
  }

  &__title {
    @include Head-25-bold;
    margin: 0;
    margin-bottom: 15px;

    @media(min-width: $md) {
      margin-bottom: 25px;
    }

    &:not(:first-child) {
      margin-top: 25px;
      padding-top: 25px;
      border-top: 1px solid $Main-5;
    }
  }

  &__text {
    @include Text-16-reg;
    margin: 0;
    gap: 5px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    a {
      position: relative;
      display: inline-block;
      padding-left: 22px;
      text-decoration: underline;
      color: $Corporate-2;
      text-decoration-color: transparent;
      transition: text-decoration-color 0.3s;

      @media(min-width: $lg) {
        padding-left: 28px;
      }

      &:hover {
        @media(min-width: $lg) {
          text-decoration-color: $Corporate-2;
        }
      }

      &:before {
        position: absolute;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url('../../assets/images/link.svg');
        background-size: contain;
        background-position: center;
        content: '';

        @media(min-width: $lg) {
          top: 2px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__footer {
    text-align: center;

    @media(min-width: $md) {
      padding: 0;
    }

    br {
      @media(min-width: $md) {
        display: none;
      }
    }

    .button {
      width: 100%;

      @media(min-width: $md) {
        width: unset;
      }
    }
  }

  &__delete {
    @include Text-16-reg;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
    color: $Main-1;
    border: 0;
    background: 0;
    text-decoration: none;
    transition: color 0.3s;
    gap: 10px;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }

    svg {
      width: 24px;
      height: 24px;
      stroke: currentColor;
    }
  }
}